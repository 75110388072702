import React, { useEffect, useState } from "react";
import { FaTimes, FaEdit } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDeleteFaqMutation, useEditFaqByAdminMutation, useGetAllFaqsQuery, useSaveFaqsMutation, useUpdateFaqMutation } from "../../services/UserManageApi"; // You need a mutation for updating
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap Modal

const FaqEditor = () => {
  const [addedFaqs, setAddedFaqs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null); 

  const [saveFaqsByAdmin, { isLoading }] = useSaveFaqsMutation();
  const { data: retrieveFaqs, refetch, isLoading: faqLoading } = useGetAllFaqsQuery();
  const [deleteFaq, { isLoading: isDeleting }] = useDeleteFaqMutation();
  const [editFaq, {isLoading: isEditing}] = useEditFaqByAdminMutation();

  useEffect(() => {
    if (retrieveFaqs?.status) {
      setFaqs(retrieveFaqs?.data[0]?.faqs || []);
    }
  }, [retrieveFaqs]);

  const addFaq = () => {
    if (title && description) {
      setAddedFaqs([...addedFaqs, { title, description }]);
      setTitle("");
      setDescription("");
    } else {
      alert("Please fill in both title and description!");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteFaq(id).unwrap();
      if (res?.status) {
        toast.success(res?.data?.message);
        refetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeFaq = (index) => {
    setAddedFaqs(addedFaqs.filter((_, i) => i !== index));
  };

  const saveFaqs = async () => {
    try {
      const response = await saveFaqsByAdmin({ faqData: addedFaqs }).unwrap();
      if (response?.status) {
        toast.success(response?.data.message);
        refetch(); // Refresh the FAQ list after saving
      }
    } catch (error) {
      console.error("Error saving FAQs:", error);
      toast.error("Error saving FAQs");
    } finally {
      setAddedFaqs([]);
      setTitle("");
      setDescription("");
    }
  };

  const toggleDescription = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const openEditModal = (faq) => {
    setEditingFaq(faq._id);
    setTitle(faq.title);
    setDescription(faq.description);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await editFaq({id:editingFaq, title, description}).unwrap();
      console.log("response", response)
      if(response?.status){
        toast.success(response?.message);
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
    } finally {
      setShowModal(false);
      setTitle("");
      setDescription("");
      refetch();
    }
  };

  const handleCancelEdit = () => {
    setShowModal(false);
    setTitle("");
    setDescription("");
  };

  return (
    <div className="container py-4" style={{ border: "1px solid #ccc", borderRadius: "8px" }}>
      <div className="row">
        {/* Left Section: Add FAQ */}
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <h4>Add FAQ</h4>
          <div className="mb-3">
            <label className="form-label">Title:</label>
            <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter FAQ Title" />
          </div>
          <div className="mb-3">
            <label className="form-label">Description:</label>
            <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} rows="4" placeholder="Enter FAQ Description" />
          </div>
          <button onClick={addFaq} className="btn btn-primary" disabled={!title || !description}>Add FAQ</button>
        </div>

        {/* Right Section: Display Added FAQs */}
        <div className="col-md-6 d-flex flex-column">
          <h4>Added FAQs</h4>
          <div className="flex-grow-1 overflow-auto mb-3" style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "8px", height: "300px" }}>
            {addedFaqs.length > 0 ? (
              <ul className="list-group">
                {addedFaqs.map((faq, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-start">
                    <div>
                      <strong>{faq.title}</strong>
                      <p className="mb-0">{faq.description}</p>
                    </div>
                    <button onClick={() => removeFaq(index)} className="btn btn-danger btn-sm">
                      <FaTimes />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-muted">No FAQs added yet.</p>
            )}
          </div>
          {addedFaqs.length > 0 && <button onClick={saveFaqs} className="btn btn-success">{isLoading ? "Saving FAQs..." : "Save All FAQs"}</button>}
        </div>
      </div>

      {/* All FAQs List Section */}
      <div className="mt-4">
        <h4>All FAQs</h4>
        <div className="list-group">
          {faqs.length > 0 ? (
            faqs.map((faq, index) => (
              <div key={index} style={{ position: "relative" }} className="list-group-item d-flex justify-content-between align-items-start">
                <div onClick={() => toggleDescription(index)} style={{ cursor: "pointer" }}>
                  <strong>{faq.title}</strong>
                  {expandedIndex === index && <p className="mb-0">{faq.description}</p>}
                </div>
                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
               <button onClick={() => openEditModal(faq)} className="btn btn-warning btn-sm">
                  <FaEdit />
                </button>
                <button onClick={() => handleDelete(faq._id)} className="btn btn-danger btn-sm">
                  <FaTimes />
                </button>
                {isDeleting && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent white backdrop
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 10, // Ensures it appears above other elements
                    }}
                  >
                    <i className="bi bi-arrow-clockwise spin" style={{ fontSize: "2rem" }}></i>
                  </div>
                )}
                </div>
 
              </div>
            ))
          ) : (
            <p className="text-muted">No FAQs available.</p>
          )}
        </div>
      </div>

      {/* Edit FAQ Modal */}
      <Modal show={showModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title:</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter FAQ Title"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Description:</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              placeholder="Enter FAQ Description"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button variant="primary" disabled={isEditing} onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FaqEditor;
