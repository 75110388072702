import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAddUserState } from '../../../slices/addUserSlice';
import { toast } from 'react-toastify';
import { GetUrl } from '../../../config/GetUrl';

const BusinessImagesForm = ({ currentPage, setCurrentPage , handleClose , refetch}) => {
  const [businessImages, setBusinessImages] = useState({
    logo: null, // For the logo image (single image)
    banner: [], // For banner images (multiple images)
  });

  const [formData, setFormData] = useState(new FormData()); 
  const [userId , setUserId] = useState('');
  const addUserState = useSelector(state => state.addUser)

  const dispatch = useDispatch();

  useEffect(() => {
   setUserId(addUserState.userId);
   console.log("userId : ", addUserState.userId);
   formData.append('userId', addUserState.userId);
  } ,[])

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'logo') {
      setBusinessImages((prevState) => ({
        ...prevState,
        logo: files[0], 
      }));

      formData.set('logo', files[0]); 
      setFormData(formData);
    } else if (name === 'banner') {
      const selectedFiles = Array.from(files); 
      setBusinessImages((prevState) => ({
        ...prevState,
        banner: [...prevState.banner, ...selectedFiles], 
      }));

      selectedFiles.forEach((file) => {
        formData.append('banner', file); 
      });
      setFormData(formData);
    }
  };

  const handleTagRemove = (image) => {
    setBusinessImages((prevState) => ({
      ...prevState,
      banner: prevState.banner.filter((item) => item !== image), // Remove selected image from banner
    }));

    // Remove the deleted image from FormData
    const newFormData = new FormData();
    businessImages.banner.forEach((file) => {
      if (file !== image) {
        newFormData.append('banner', file);
      }
    });
    setFormData(newFormData);
  };

  const handleBack = () => {
    setCurrentPage(1); // Go back to the previous page
  };

  const handleSubmit = () => {
    // Here you can send the formData (multipart) to your backend
    // Example API request with FormData
    fetch(`${GetUrl.API_URL}save-buisness-images`, {
      method: 'PATCH',
      body: formData, // Send the form data to backend
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(resetAddUserState());
        refetch();
        handleClose();
        console.log('Form submitted successfully:', data);
        toast.success('User created successfully');
      })
      .catch((error) => {
        console.error('Error during form submission:', error);
      });
  };

  return (
    <form className="p-4" encType="multipart/form-data">
      <h4 className="mb-4">Upload Business Images</h4>

      {/* Logo Image Field */}
      <div className="mb-3">
        <label htmlFor="logo" className="form-label">
          Logo Image
        </label>
        <input
          type="file"
          className="form-control"
          id="logo"
          name="logo"
          onChange={handleFileChange}
        />
        {businessImages.logo && (
          <div className="mt-2">
            <h6>Selected Logo:</h6>
            <p>{businessImages.logo.name}</p>
            {/* Display logo preview */}
            <img
              src={URL.createObjectURL(businessImages.logo)}
              alt="Logo Preview"
              className="img-fluid"
              style={{ maxWidth: '100px' }}
            />
          </div>
        )}
      </div>

      {/* Banner Images Field */}
      <div className="mb-3">
        <label htmlFor="banner" className="form-label">
          Banner Image (Select multiple images)
        </label>
        <input
          type="file"
          className="form-control"
          id="banner"
          name="banner"
          multiple // Allows multiple file selection
          onChange={handleFileChange}
        />
        {businessImages.banner.length > 0 && (
          <div className="mt-2">
            <h6>Selected Banner Images:</h6>
            <div className="d-flex flex-wrap">
              {businessImages.banner.map((file, index) => (
                <div key={index} className="p-2">
                  <span
                    className="badge bg-primary text-wrap"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      marginRight: '5px',
                      padding: '5px 10px',
                    }}
                  >
                    {file.name}
                    <button
                      type="button"
                      className="btn btn-sm btn-danger ms-2"
                      onClick={() => handleTagRemove(file)}
                    >
                      &times;
                    </button>
                  </span>
                </div>
              ))}
            </div>
            {/* Display banner previews */}
            <div className="d-flex flex-row">
              {businessImages.banner.map((file, index) => (
                <div key={index} className="p-2">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Banner Preview ${index + 1}`}
                    className="img-fluid"
                    style={{ maxWidth: '100px', marginRight: '10px' }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Back and Submit Buttons */}
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-secondary"
          onClick={handleBack}
        >
          Back
        </button>
        <button
          className="btn btn-success"
          type="button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default BusinessImagesForm;
