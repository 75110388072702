import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupportDetails,
  supportReply,
} from "../redux/actions/supportActions";
import { getBaseUrl, GetUrl } from "../../config/GetUrl";

export default function SupportModal({ show, setShow, supportId }) {
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const { supportDetails, supportChat } = useSelector((state) => state.support);

  useEffect(() => {
    if (supportId) {
      dispatch(getSupportDetails(supportId));

      const intervalId = setInterval(() => {
        dispatch(getSupportDetails(supportId));
      }, 10 * 1000); 

      return () => clearInterval(intervalId);
    }
  }, [dispatch, supportId]);

  const handleSubmit = (values) => {
    const formdata = new FormData();
    formdata.append("message", values.message);
    formdata.append("id", supportId);
    dispatch(supportReply(formdata));
  };

  function formatDate(dateString) {
    const date = new Date(dateString); 
  
    // Check if the date is valid
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return 'Invalid date';
    }
  
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
  
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="container">
            <div class="row clearfix">
              <div class="col-lg-12">
                <div class="chat-app">
                  <div class="chat">
                    <div class="chat-history">
                      <ul class="m-b-0">
                        <li class="clearfix">
                          <div class="message my-message">
                            {supportDetails?.description}
                            {/* <div class="message-data">
                                    <span class="message-data-time">
                                      10:12 AM, Today
                                    </span>
                                  </div> */}
                          </div>
                        </li>
                        {supportChat?.map((chatObj) => {
                          if (chatObj?.sent_by === "Admin") {
                            return (
                              <li class="clearfix">
                                {/*  <div class="message-data float-right">
                                  <span class="message-data-time">
                                    10:10 AM, Today
                                  </span>
                                  <img
                              src="https://bootdey.com/img/Content/avatar/avatar7.png"
                              alt="avatar"
                            /> 
                                </div> */}
                              <div className="message other-message float-right">
                                {chatObj?.image ? (
                                  <>
                                    <div className="message-content">
                                      <img src={`${GetUrl.IMAGE_URL}${chatObj.image}`} alt="message image" className="message-image" />
                                      <p>{chatObj?.message}</p>
                                      {chatObj?.createdAt && (
                                          <div style={{marginTop:'2px', fontSize:'12px', width:'100%', display:'flex', justifyContent:'flex-end'}}>
                                            <p style={{margin:0}}>{formatDate(chatObj.createdAt)}</p>
                                          </div>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <p>{chatObj?.message}</p>
                                    <div style={{width:"100%", display:"flex", justifyContent:'flex-end'}}>
                                        {chatObj?.createdAt && (
                                          <div style={{marginTop:'2px', fontSize:'12px', width:'100%', display:'flex', justifyContent:'flex-end'}}>
                                            <p style={{margin:0}}>{formatDate(chatObj.createdAt)}</p>
                                          </div>
                                        )}
                                    </div>

                                  </div>
                                )}
                              </div>
                              </li>
                            );
                          } else {
                            return (
                              <li class="clearfix">
                                <div class="message my-message">
                                {chatObj?.image ? (
                                  <>
                                    <div className="message-content">
                                      <img style={{width:'200px', height:'auto'}} src={`${GetUrl.IMAGE_URL}${chatObj.image}`} alt="message image" className="message-image" />
                                      <p>{chatObj?.message}</p>
                                      {chatObj?.createdAt && (
                                          <div style={{marginTop:'2px', fontSize:'12px', width:'100%', display:'flex', justifyContent:'flex-end'}}>
                                            <p style={{margin:0}}>{new Date(chatObj.createdAt).toLocaleDateString()} , {new Date(chatObj.createdAt).toLocaleTimeString()}</p>
                                          </div>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <p>{chatObj?.message}</p>
                                )}
                                </div>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{ message: "" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              values?.message !== "" && handleSubmit(values);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div class="input-group mb-3">
                  <Field
                    placeholder="Type here..."
                    className="form-control"
                    type="text"
                    name="message"
                  />
                  {/* <ErrorMessage name="email" component="div" /> */}
                  <span class="input-group-text" id="basic-addon2">
                    <button
                      className="btn send-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <i class="bx bxs-send"></i>
                    </button>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
