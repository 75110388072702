import { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaTrash } from "react-icons/fa";
import { addBusinessProfile } from "../../../slices/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap"; // Using Bootstrap modal for confirmation

const socialOptions = [
  { name: "Facebook", icon: <FaFacebook />, value: "facebook" },
  { name: "Instagram", icon: <FaInstagram />, value: "instagram" },
  { name: "Twitter", icon: <FaTwitter />, value: "twitter" },
  { name: "LinkedIn", icon: <FaLinkedin />, value: "linkedin" },
];

export default function SocialLinksInput({ businessProfile, setBusinessProfile }) {
  const [selectedPlatform, setSelectedPlatform] = useState(socialOptions[0].value);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState(null);

  const dispatch = useDispatch();
  const addUserState = useSelector((state) => state.addUser);

  const handleAddLink = () => {
    if (!url.trim()) return;
    const newLink = { iconPath: selectedPlatform, url };
    setBusinessProfile((prev) => ({
      ...prev,
      socialLinks: [...(prev.socialLinks || []), newLink],
    }));
    dispatch(
      addBusinessProfile({
        ...businessProfile,
        socialLinks: [...(businessProfile.socialLinks || []), newLink],
      })
    );
    setUrl("");
  };

  const handleDeleteClick = (index) => {
    setLinkToDelete(index);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (linkToDelete === null) return;
    const updatedLinks = businessProfile.socialLinks.filter((_, idx) => idx !== linkToDelete);

    setBusinessProfile((prev) => ({
      ...prev,
      socialLinks: updatedLinks,
    }));

    dispatch(
      addBusinessProfile({
        ...businessProfile,
        socialLinks: updatedLinks,
      })
    );

    setShowModal(false);
    setLinkToDelete(null);
  };

  useEffect(() => {
    console.log("businessProfile changed:", businessProfile);
    console.log("addUserState changed:", addUserState);
  }, [businessProfile, addUserState]);

  return (
    <div className="mb-3">
      <label htmlFor="socialLinks" className="form-label">
        Social Links
      </label>
      <div className="d-flex gap-2">
        <select
          className="form-select"
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
        >
          {socialOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="form-control"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter social link"
        />
      </div>
      <button className="btn btn-primary mt-2" onClick={handleAddLink} type="button">
        Add Link
      </button>
      <ul className="mt-2">
        {businessProfile.socialLinks?.map((link, index) => (
          <li key={index} className="d-flex align-items-center gap-2">
            {socialOptions.find((opt) => opt.value === link.iconPath)?.icon} {link.url}
            <FaTrash className="text-danger cursor-pointer" onClick={() => handleDeleteClick(index)} />
          </li>
        ))}
      </ul>

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this social link?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
