import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetailQuery } from "../../services/UserApi";
import RegisterUserForm from "./addUserForms/editUserForm1";
import BusinessProfileDetails from "./addUserForms/editUserForm2";
import BusinessImagesForm from "./addUserForms/editUserForm3";
import { initialiseUserState } from "../../slices/addUserSlice";

const EditUserModal = ({ show, handleClose , refetch , userId}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [images, setImages] = useState({
    logo:null,
    banner:[]
  })
  

  const dispatch = useDispatch();

  const { data, isLoading, isError } = useGetUserDetailQuery(userId);
  const addUserState = useSelector((state) => state.addUser);



  useEffect(() => {
    if (data?.data?.user) {
        dispatch(initialiseUserState(data?.data?.user?.user));
        setImages((prev) => {
            return {
                ...prev,
                logo: data?.data?.user?.user?.buisnessProfile?.logo || '',
                banner: data?.data?.user?.user?.buisnessProfile?.banner || ''
            }
        })
    }
}, [data, dispatch]);

useEffect(() => {
  console.log("addUserState changed : ", addUserState)
  console.log("user changed : ", data?.data?.user?.user)
}, [addUserState, data])

  const pages = [
      <RegisterUserForm setCurrentPage={setCurrentPage} />,
      <BusinessProfileDetails setCurrentPage={setCurrentPage}/>,
      <BusinessImagesForm 
          setCurrentPage={setCurrentPage} 
          handleClose={handleClose} 
          refetch={refetch} 
          userId={userId}
          images={images}
      />,
  ];

  return (
    <Modal show={show} onHide={handleClose} centered style={{width:'100%'}}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>{pages[currentPage]}</Modal.Body>
    </Modal>

  );
};

export default EditUserModal;
