export const GetUrl = {
    API_URL: "	https://gib-api.alcax.com/api/v1/admin/",
     IMAGE_URL:"https://gib-api.alcax.com/files/"
     // API_URL: "http://localhost:8080/api",
      // IMAGE_URL: "http://localhost:8080/files/",
    
  };

  export const getBaseUrl = {
    BASE_URL: "	https://gib-api.alcax.com/",
  }
  
