import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBusinessProfile, setUserId } from '../../../slices/addUserSlice';
import { useSaveUserByAdminMutation } from '../../../services/UserManageApi';
import { toast } from 'react-toastify';
import { useGetCategoryQuery } from '../../../services/CategoryApi';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import SocialLinksInput from './socialLinksForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const BusinessProfileDetails = ({ currentPage, setCurrentPage }) => {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  
  // Accessing addUserState directly
  const addUserState = useSelector(state => state.addUser);

  const [saveUserByAdmin, { isLoading, isError, error }] = useSaveUserByAdminMutation();
  const { data: retrieveCategory, refetch, isLoading: categoryLoading } = useGetCategoryQuery();

  useEffect(() => {
    refetch();
    if (retrieveCategory?.data?.category) {
      setCategories(retrieveCategory.data.category);
    }
  }, []); 
  // Set form values based on addUserState businessProfile
  useEffect(() => {
    if (addUserState?.businessProfile) {
      setBusinessProfile({
        name: addUserState.businessProfile.name || '',
        socialLinks: addUserState.businessProfile.socialLinks || [],
        description: addUserState.businessProfile.description || '',
        phone: addUserState.businessProfile.phone || '',
        businessState: addUserState.businessProfile.state || '',
        url: addUserState.businessProfile.url || '',
        category: addUserState.businessProfile.category || '',
        address: addUserState.businessProfile.address || '',
        lat: addUserState.businessProfile.lat || '',
        long: addUserState.businessProfile.long|| ''
      });
    }
  }, [addUserState]); // Update whenever addUserState changes


  const [businessProfile, setBusinessProfile] = useState({
    name: '',
    socialLinks: [],
    description: '',
    phone: '',
    businessState: '',
    url: '',
    category: '',
    address: '',
    lat: '',
    long: ''
  });

 

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {console.log(businessProfile)} , [businessProfile])

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setBusinessProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value
    }));

    dispatch(addBusinessProfile({
      ...businessProfile,
      [name]: value
    }));
  };

  useEffect(() => {}, [businessProfile, addUserState])
  
  
  const handleNext = async () => {
    setIsSubmitting(true);
    try {
      const result = await saveUserByAdmin(addUserState);
      if (result?.data?.status === true) {
        dispatch(setUserId(result?.data?.data?.userId));
        setCurrentPage(2);
      } else {
        toast.error(result?.data?.data?.message || 'Failed to save user');
      }
    } catch (err) {
      toast.error('An error occurred while saving user');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setCurrentPage(0);
  };

  return (
    <form className="p-4">
      <h4 className="mb-4">Business Profile Details</h4>

      {/* Name Field */}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Business Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={businessProfile.name}
          onChange={handleChange}
          placeholder="Enter business name"
          required
        />
      </div>

      {/* Description Field */}
      <div style={{ 
          width: '100%',
          '.ql-container': {
            minHeight: '150px',
            fontSize: '1rem',
            borderRadius: '0.375rem',
            borderColor: '#ced4da'
          },
          '.ql-toolbar': {
            borderRadius: '0.375rem',
            borderColor: '#ced4da'
          }
        }}>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <ReactQuill
            theme="snow"
            value={businessProfile.description}
            onChange={(content) => handleChange({
              target: {
                name: 'description',
                value: content
              }
            })}
            style={{
              width: '100%',
              marginBottom: '50px'  // Space for toolbar
            }}
            modules={{
              toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link', 'clean']
              ]
            }}
          />
        </div>
        </div>

      {/* Social Links */}
      {/* <div className="mb-3">
      <label htmlFor="socialLinks" className="form-label">
        Social Links
      </label>
      <div className="d-flex gap-2">
        <select
          className="form-select"
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
        >
          {socialOptions.map((option) => (
            <option key={option.value} value={option.value} style={{display: "flex", alignItems: "center", gap: "5px"}}>
              <FaFacebook/> {option.name} 
            </option>
   
          ))}
        </select>
        <input
          type="text"
          className="form-control"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter social link"
        />
      </div>
      <button className="btn btn-primary mt-2" onClick={handleAddLink}>
        Add Link
      </button>
      <ul className="mt-2">
        {businessProfile.socialLinks?.map((link, index) => (
          <li key={index}>
            {socialOptions.find((opt) => opt.value === link.iconPath)?.icon} {link.url}
          </li>
        ))}
      </ul>
    </div> */}
    <SocialLinksInput businessProfile={businessProfile} setBusinessProfile={setBusinessProfile} handleChange={handleChange} />

      {/* Phone Field */}
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          Phone
        </label>
        <input
          type="text"
          className="form-control"
          id="phone"
          name="phone"
          value={businessProfile.phone}
          onChange={handleChange}
          placeholder="Enter phone number"
          required
        />
      </div>

      {/* State Field */}
      <div className="mb-3">
        <label htmlFor="state" className="form-label">
          State
        </label>
        <input
          type="text"
          className="form-control"
          id="state"
          name="state"
          value={businessProfile.businessState}
          onChange={handleChange}
          placeholder="Enter state"
        />
      </div>

      {/* URL Field */}
      <div className="mb-3">
        <label htmlFor="url" className="form-label">
          URL
        </label>
        <input
          type="url"
          className="form-control"
          id="url"
          name="url"
          value={businessProfile.url}
          onChange={handleChange}
          placeholder="Enter business URL"
        />
      </div>

      {/* Category Dropdown */}
      <div className="mb-3">
        <label htmlFor="category" className="form-label">
          Category
        </label>
        <select
          className="form-select"
          id="category"
          name="category"
          value={businessProfile.category}
          onChange={handleChange}
          required
        >
          <option value="">Select a category</option>
          {categories?.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {/* Address Field */}
      <div className="mb-3">
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <input
          type="text"
          className="form-control"
          id="address"
          name="address"
          value={businessProfile.address}
          onChange={handleChange}
          placeholder="Enter address"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="lat" className="form-label">
          latitude
        </label>
        <input
          type="text"
          className="form-control"
          id="lat"
          name="lat"
          value={businessProfile.lat}
          onChange={handleChange}
          placeholder="Enter latitude"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="long" className="form-label">
          longitude
        </label>
        <input
          type="text"
          className="form-control"
          id="long"
          name="long"
          value={businessProfile.long}
          onChange={handleChange}
          placeholder="Enter longitude"
        />
      </div>

      {/* Back and Next Buttons */}
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleNext}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          ) : (
            'Next'
          )}
        </button>
      </div>
    </form>
  );
};

export default BusinessProfileDetails;
